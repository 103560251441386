.modal-container {
  font-size: 12px;
  display: block;
  z-index: 1050;
  cursor: pointer;
}
.popup-content {
  background-color: white;
  width: 40%;
  margin-left: 30%;
  color: black;
}
.modal-container > .header {
  width: 100%;
font-size: 2rem;
  text-align: center;
  /* padding: 5px; */
}
.modal-container > .content {
  width: 100%;
  padding: 4%;
  display: flex;align-items: center;
  justify-content: space-around;
}

.modal-container > .close {
  cursor: pointer;
  position: absolute;
  display: block;
  padding: 2px 5px;
  line-height: 20px;
  right: -10px;
  top: -10px;
  font-size: 24px;
  background: #ffffff;
  border-radius: 18px;
  border: 1px solid #cfcece;
}

.popup-overlay {
  background: rgba(0, 0, 0, 0.7);
  /*opacity:% !important;*/
}
.below_title_line {
  width: 80px;
  height: 4px;
  margin: 10px;
  background: #32B265 ;
  margin: 0 auto;}
  .country-box{
      text-align: center;
   
  }
  .country-box    img{
      width: 50%;
      height:50%;
  }
.tooltip > div.tooltip-inner {
  background-color: #4de78b !important;
  color: rgb(255, 255, 255) !important;
  font-weight: 800;
    }

  a {
      outline: none;
  }